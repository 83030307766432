<template>
    <div class="convenios">
        <div class="grid-box">
			<Breadcrumb :levels="breadcrumbLevels"/>
            <div class="main-block footer">
                <v-layout>
                    <div class="boxapp">
                        <div class="boxapp-head">
                            <a href="#" @click="$router.go(-1)">
                                <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                            </a>
                            <h1>Convênio de Boleto</h1>
                        </div>
                        <div class="box-main">
                            <div class="section">
                                <v-form>
                                <v-layout row mw-half>                                    
                                    <v-flex xs12>
                                        <InputText
                                            v-model="ticketConfig.eCommerceCode"
                                            type="text"
                                            place-holder="Código do e-commerce"
                                            textLabel="Código do e-commerce"
                                            invalidInputMessage="Preencha o código do e-commerce"
                                            labelCharLimit="6"
                                        />
                                    </v-flex>
                                </v-layout>

                                <v-layout row mw-half>                                    
                                    <v-flex xs12>
                                        <InputText
                                            v-model="ticketConfig.lastTicketReferenceCodeGenerated"
                                            type="text"
                                            place-holder="Código de referência de transação"
                                            textLabel="Último código de refência de transação (Reftran)"
                                            invalidInputMessage="Preencha o código do e-commerce"
                                            labelCharLimit="17"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-btn
                                    flat
                                    round
                                    color="white"
                                    class="btn-primary flex-inherit px-5 mt-3 ml-auto"
                                    large
                                    @click.prevent="save"
                                >{{ this.ticketConfig.id > 0 ? 'Atualizar' : 'Salvar' }}</v-btn>

                                <v-btn
                                    flat
                                    round
                                    color="white"
                                    class="btn-secondary flex-inherit px-5 mt-3 ml-auto"
                                    large
                                    @click="reset"
                                >Limpar</v-btn>

                                </v-form>

                            </div>
                            <br><br><br>
                            <h3>Lista de Convênios</h3>
                            <table class="v-datatable v-table theme--light">
                                <thead>
                                    <tr>
                                        <th class="text-sm-left">Id</th>
                                        <th class="text-sm-left">e-Commerce</th>
                                        <th class="text-sm-left">Reftran</th>
                                        <th class="text-sm-left">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in ticketConfigList" :key="item.id">
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.eCommerceCode }}</td>
                                        <td>{{ item.lastTicketReferenceCodeGeneratedString }}</td>
                                        <td><button primary @click="editTicketConfig(item)"><v-icon>edit</v-icon> Editar</button></td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>
                    </div>
                    
                    <div class="box-help-spacer"></div>
                    <HelpBox :text="helpTextBox" />
                </v-layout>
            </div>
    </div>
    </div>
</template>

<script type="plain/text">

import HeroInner from "@/components/HeroInner.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfigManagerService from "@/scripts/services/configManager.service.js";
import TicketConfig from "@/scripts/models/ticketConfig.model.js";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
    components: {
        HeroInner, HelpBox, HelpBoxText, Breadcrumb
    },
    data: () => ({
        ticketConfig: new TicketConfig(),
        configManagerService: new ConfigManagerService(),
        ticketConfigList: [],
        headers: [ {text: "Código e-Commerce" }, {text:"Reftran"}],
        search: "",
        breadcrumbLevels: [
            {text: "Início"},
            {text: "Configurações"},
        ],
        helpTextBox: HelpBoxText.TicketConfig
    }),
    created() {
        this.findAll();
        this.reset()
    },
    methods: {
        load(){
            if(this.$route.query.id > 0){
                this.configManagerService.findTickeConfigById(this.callbackLoad, this.$route.query.id);
            }else{
                this.reset();
            }
        },
        save(){
            

            if(!this.validate()){
                CommonHelper.swal("", "Erro de validação, verifique se os campos estão digitados corretamente", "error");
            }else{
                this.configManagerService.saveTicketConfig(this.callbackSave, this.ticketConfig);
            }
        },
        callbackLoad(data){
            if(data){
                this.ticketConfig = data
                this.ticketConfig.eCommerceCode = data.eCommerceCode + ""
                this.ticketConfig.lastTicketReferenceCodeGenerated = data.lastTicketReferenceCodeGeneratedString
                delete this.ticketConfig.lastTicketReferenceCodeGeneratedString
            }
        },
        callbackSave(data){
            if(data){
                if(this.ticketConfig.id == 0){

                    data.eCommerceCode = data.eCommerceCode + ""
                    data.lastTicketReferenceCodeGenerated = data.lastTicketReferenceCodeGeneratedString

                    this.ticketConfigList.push(data)
                }

                this.reset();

                CommonHelper.swal("", "Convênio salvo com sucesso!", "success");
            }
        },
        findAll(){
            this.configManagerService.findAllTickeConfig(this.callbackLoadAll)
        },

        callbackLoadAll(data){
            if(data){
                this.ticketConfigList = data;
                this.ticketConfigList = this.ticketConfigList.map(function(item){
                    item.eCommerceCode = item.eCommerceCode + ""
                    item.lastTicketReferenceCodeGenerated = item.lastTicketReferenceCodeGeneratedString
                    return item;
                })
            }
        },

        editTicketConfig(ticketConfig){
            this.ticketConfig = ticketConfig;
        },

        validate(){
            let error = false;
            if(!this.ticketConfig.eCommerceCode || this.ticketConfig.eCommerceCode.length < 6){
                error = true
            }
            if(!this.ticketConfig.lastTicketReferenceCodeGenerated || this.ticketConfig.lastTicketReferenceCodeGenerated.length < 17){
                error = true
            }

            return !error;
        },
        reset () {
            this.ticketConfig = new TicketConfig();
            this.ticketConfig.returningUrl = "https://{SUBDOMINIO}.abraceumacausa.com.br";
            this.ticketConfig.informingUrl = "ticket";
            this.ticketConfig.ticketTypeId = 2;
            this.ticketConfig.daysForDueDate = 7;
            this.ticketConfig.ticketMessage = "Sr. Caixa, não receber após o vencimento. Doação para a campanha {NOME_CAMPANHA}.";
            this.ticketConfig.daysSinceCheckoutThatUserCanGenerateTicketAgain = 30;
        },
    }
    
}
</script>