export default class TicketConfig {
    constructor() {
        this.id = 0;
        this.returningUrl = "";
        this.informingUrl = "";
        this.eCommerceCode = "0";
        this.lastTicketReferenceCodeGenerated = "0";
        this.ticketTypeId = 2;
        this.daysForDueDate = 0;
        this.ticketMessage = "";
    }
}